<template>
  <v-container class="crud-content" fill-height>
    <ListLayout :title="title" :buttonActions="buttonActions">
      <template v-slot:filter>
        <DataFilter @filter="changeFilter" :search="search" :filters="filters" />
      </template>
      <template v-slot:content>
        <v-data-table
          :headers="header"
          :loading="loading"
          :items="content"
          class="elevation-1 custom-table"
          :options.sync="options"
          :server-items-length="totalElements"
          multi-sort
          :footer-props="{
            'items-length': 100,
            'items-per-page-text': 'Linhas por página',
            'items-per-page-options': [10, 20, 50],
            pageText: '{0}-{1} de {2}',
          }"
        >
          <template v-slot:[`item.status`]="{ item }">
            <span :class="`${formatStatus(item).chipTextColor}`">
              {{ formatStatus(item).label }}
            </span>
          </template>
          <template v-slot:[`item.variants`]="{ item }">
            <v-chip class="vchip-columns">
              {{ item.variants.length === 0 ? 'Nenhuma variante' : item.variants.length }}
            </v-chip>
          </template>
          <template v-slot:[`item.skuProperties`]="{ item }">
            <v-chip class="vchip-columns">
              {{ item.skuProperties.length === 0 ? 'Nenhuma propriedade SKU' : item.skuProperties.length }}
            </v-chip>
          </template>
          <template v-slot:[`item.productProperties`]="{ item }">
            <v-chip class="vchip-columns">
              {{ item.productProperties.length === 0 ? 'Nenhuma propriedade' : item.productProperties.length }}
            </v-chip>
          </template>
          <template v-if="isAdmin" v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-center">
              <v-icon color="primary" @click="openDetail(item.id)">mdi-pencil</v-icon>
              <v-icon color="primary" @click="showAlertModal(item)">mdi-delete</v-icon>
            </div>
          </template>
          <template v-slot:no-data>
            <div class="pt-10 pb-10">
              Nenhum resultado encontrado
            </div>
          </template>
        </v-data-table>
      </template>
    </ListLayout>
    <AlertModal :showModal="showModal">
      <template v-slot:header>
        <div class="d-flex flex-column">
          Atenção!
        </div>
      </template>
      <template v-slot:content>
        <div class="d-flex flex-column">
          <div class="mb-3">
            Todas as informações deste tipo de produto serão perdidas com esta ação
          </div>
          <div>Deseja mesmo excluir o tipo de produto?</div>
        </div>
      </template>
      <template v-slot:footer>
        <v-btn outlined color="primary" class="mr-3" @click="closeModal()">CANCELAR EXCLUSÃO</v-btn>
        <v-btn color="primary" @click="remove()">SIM, QUERO EXCLUIR</v-btn>
      </template>
    </AlertModal>
  </v-container>
</template>

<script>
import ListLayout from '@/components/template/list/ListLayout';
import DataFilter from '@/components/template/list/DataFilter';
import AlertModal from '@/components/modal/AlertModal';
import notification from '@/service/notification';
import dataCrudMixin from '../../components/template/crud/mixins/dataCrudMixin';
import isAdmin from '@/utils/user-utils';
import {
  listDadosProductTypesVariants,
  deleteProductType,
  listProductType,
} from '@/service/product-types/product-types-service';
import { listDadosProductProperties } from '@/service/product/product-service';
import { listDadosSku } from '@/service/sku/sku-service';
import TipoProdutoStatusOptions from '@/static-data/TipoProdutoStatusOptions';
import { routerPush } from '@/service/kplaceRouter';

export default {
  components: {
    AlertModal,
    ListLayout,
    DataFilter,
  },
  mixins: [dataCrudMixin],
  data() {
    return {
      loading: false,
      buttonActions: [{ name: 'CADASTRAR TIPO DE PRODUTO', action: () => routerPush('/product-type/forms') }],
      search: '',
      options: {},
      isAdmin: isAdmin(),
      productTypeClickedItem: {},
      showModal: false,
      filters: [
        {
          name: 'productProperties.code',
          param: 'code',
          label: 'Propriedades',
          labelSelect: 'label',
          placeHolder: 'Selecione',
          type: 'CustomSelect',
          service: async (param, filter, itemsPerPage) => listDadosProductProperties(param, filter, itemsPerPage),
          totalAmount: null,
          filled: true,
        },
        {
          name: 'skuProperties.code',
          param: 'code',
          label: 'Propriedades SKU',
          labelSelect: 'label',
          placeHolder: 'Selecione',
          type: 'CustomSelect',
          service: async (param, filter, itemsPerPage) => listDadosSku(param, filter, itemsPerPage),
          totalAmount: null,
          filled: true,
        },
        {
          name: 'variants.code',
          param: 'code',
          label: 'Variantes',
          labelSelect: 'label',
          placeHolder: 'Selecione',
          type: 'CustomSelect',
          service: async (param, filter, itemsPerPage) => listDadosProductTypesVariants(param, filter, itemsPerPage),
          totalAmount: null,
          filled: true,
        },
        {
          name: 'status',
          label: 'Status',
          labelSelect: 'label',
          placeHolder: 'Selecione',
          type: 'select',
          filled: true,
          options: TipoProdutoStatusOptions,
        },
        {
          name: 'displayName',
          label: 'Pesquisar por nome',
          xl: 10,
          lg: 9,
          placeHolder: 'Pesquise por nome do tipo de produto...',
          type: 'search',
          filled: true,
        },
      ],
      title: 'Tipos de Produtos',
      header: [
        {
          text: 'Código',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Nome do tipo de Produto',
          sortable: true,
          value: 'displayName',
        },
        {
          text: 'Propriedades',
          sortable: false,
          value: 'productProperties',
        },
        {
          text: 'Propriedades SKU',
          sortable: false,
          value: 'skuProperties',
        },
        {
          text: 'Variantes',
          sortable: false,
          value: 'variants',
        },
        {
          text: 'Status',
          sortable: false,
          value: 'status',
        },
      ],
    };
  },
  methods: {
    openDetail(id) {
      routerPush(`/product-type/forms/edit/${id}`);
    },
    formatStatus: (item) => {
      const statusMapping = {
        PUBLISHED: { label: 'Publicado', chipTextColor: 'status-column--green' },
        DRAFT: { label: 'Rascunho', chipTextColor: 'status-column--orange' },
        default: { label: 'Não publicado', chipTextColor: 'status-column--gray' },
      };

      const { label, chipTextColor } = statusMapping[item.status] || statusMapping.default;

      return {
        label,
        chipTextColor,
      };
    },
    showAlertModal(item) {
      this.productTypeClickedItem = item;
      this.showModal = !this.showModal;
    },
    closeModal() {
      this.showModal = false;
    },
    async init() {
      try {
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        const { data } = await listProductType(page, itemsPerPage, this.query, sortBy, sortDesc);
        this.content = data.register;
        this.totalElements = data.totalRegister;
      } catch (e) {
        notification(`Erro ao realizar busca de tipos de produtos : ${e}`, 'error');
      } finally {
        this.loading = false;
      }
    },
    async remove() {
      try {
        const item = this.productTypeClickedItem;
        const itemId = item.id.split(' ')[0];
        const response = await deleteProductType(itemId);
        if (response.status === 204) {
          notification('Tipo de produto removido com sucesso.', 'success');
          this.content = this.content.filter((e) => e.id !== item.id);
        } else if (response.response.data.errorCode === 'PRODUCT_TYPE_CONTAINS_PRODUCT_DEPENDENCY') {
            notification(`${response.response.data.message}`, 'warning');
          } else {
           notification('Erro ao remover o tipo de produto.', 'error');
        }
      } catch (error) {
        notification(`Erro ao remover, ${error.response.data.message}`, 'error');
      }
      this.productTypeClickedItem = {};
      this.showModal = false;
    },
  },
  watch: {
    options: {
      handler() {
        this.init();
      },
    },
  },
  async mounted() {
    if (this.options.sortBy.length === 0) {
      this.options.sortBy.push('displayName');
      this.options.sortDesc.push(true);
    }
    if (this.isAdmin) {
      this.header.push({ text: 'Ações', value: 'actions', sortable: false, align: 'center' });
    }
    await this.init();
  },
};
</script>

<style lang="scss">
.vchip-columns.theme--light.v-chip {
  background-color: #f44fe3;
  color: #f8f8f8;
  font-family: $font-family-medium;
}

.status-column {
  &--orange {
    font-family: $font-family-medium;
    color: orange;
  }
  &--green {
    font-family: $font-family-medium;
    color: #02d13f;
  }
  &--gray {
    font-family: $font-family-medium;
    color: gray;
  }
}
</style>

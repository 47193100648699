import { get } from '@/service/api';

export async function getSku(id) {
  const resp = await get(`ui-integrator/category/${id}`);
  return resp;
}

export async function listSku(param, filter, itemsPerPage) {
  const { data } = await get(`ui-integrator/sku-properties/fetch?size=${itemsPerPage ? `${itemsPerPage}` : '20'}&filter=${param ? `${filter} = !${param}` : ''}`);
  return data;
}

export async function listDadosSku(param, filter, itemsPerPage) {
  return listSku(param, filter, itemsPerPage);
}

export async function countSku() {
  const data = await listSku();
  return data.totalRegister;
}
